import { useMediaQuery, useTheme } from "@material-ui/core"
import { FormHandles } from "@unform/core"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import tokens from '../bootstrap/Tema/tokens.json'


export function useHooks() {
  const useApptheme = useTheme()
  const mediaQuery = useMediaQuery(useApptheme.breakpoints.up('lg'))
  const formRef = useRef<FormHandles | null>(null)
  const { t } = useTranslation()

  return {
    mediaQuery,
    formRef,
    tokens,
    translation: t
  }
}
