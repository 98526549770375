import styled from 'styled-components'
import { StyledProps } from '../../bootstrap/Tema/styledProps'

export const Footer = styled.footer<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: ${props => props.minHeight};
  width: 100%;
  background: #29292E;
  margin: auto 0rem 0rem 0rem;
  padding: ${props => props.padding};
`

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background: #505059;
  cursor: pointer;

  svg{
    color: #fff;
  }
`
export const IconContainer = styled.div`
  svg {
      color: #835AFD;

      :hover {
        color: #fff;
      }
  }

`
export const Texto = styled.h1 <StyledProps>`
  font-style: normal;
    font-weight: 400;
    font-size: ${props => props.fontSize};
    line-height: 1rem;
    letter-spacing: 0.025rem;
    color: #fff;
`
