import React from 'react'
import * as  SG from '../globalStyledCompoent';
import { useHooks } from '../../hooks/useHooks';
import { Texto, ContainerCards, Icon, Card } from './styles';

export function Habilidades() {
  const { mediaQuery } = useHooks()

  const listCard = [
    {
      icon: <img src="assets/cssEscuro.png" alt="Imagem ilustrativa" className='icon' />,
      title: 'Css',

    },
    {
      icon: <img src="assets/htmlEscuro.png" alt="Imagem ilustrativa" className='icon' />,
      title: 'Html',

    },
    {
      icon: <img src="assets/javascriptEscuro.png" alt="Imagem ilustrativa" className='icon' />,
      title: 'Javascript',
    },
    {
      icon: <img src="assets/reactEscuro.png" alt="Imagem ilustrativa" className='icon' />,
      title: "React",

    },
    {
      icon: <img src="assets/typescriptEscuro.png" alt="Imagem ilustrativa" className='icon' />,
      title: "Typescript",

    },
    {
      icon: <img src="assets/gitEscuro.png" alt="Imagem ilustrativa" className='icon' />,
      title: "Git",

    },
  ]

  return (
    <SG.Content
      id="habilidades"
      padding='24px'>
      <Texto
        fontSize={mediaQuery ? "48px" : "24px"}
        color="#fff" >
        Habilidades
      </Texto>
      <ContainerCards flexDirection={mediaQuery ? "row" : "column"}>
        {listCard.map((item, index) => (
          <Card key={index} >
            <Icon
              margin={mediaQuery ? "80px 0 80px 0" : "5px"}
              width={mediaQuery ? "52px" : "24px"}
              height={mediaQuery ? "52px" : "24px"}>
              {item.icon}
            </Icon>
            <Texto fontSize={mediaQuery ? "52px" : "24px"}>
              {item.title}
            </Texto>
          </Card>
        ))}
      </ContainerCards >
    </SG.Content >
  )
}
