import styled from 'styled-components'
import { StyledProps } from '../../bootstrap/Tema/styledProps'


export const Texto = styled.h1 <StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.color || "#fff"};
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight || 700};
  span{
    margin: 5px
  }
`
export const ContainerCards = styled.div <StyledProps>`
  display: flex;
  flex-direction: ${props => (props.flexDirection)};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${props => (props.padding)};
  gap: 10px;
`
export const Card = styled.div <StyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`

export const Icon = styled.div <StyledProps>`
  display: flex;
  flex-direction: ${props => (props.flexDirection)};
  justify-content: center;
  align-items: center;
  margin: ${props => (props.margin)};

img {
   height: ${props => (props.height)};
   width: ${props => (props.width)};
}

`
