import React from 'react'
import * as  SG from '../../components/globalStyledCompoent';
import { useHooks } from '../../hooks/useHooks';
import { Texto, Foto } from './styles';

export function Sobre() {
  const { mediaQuery } = useHooks()

  return (
    <SG.Content
      flexDirection={mediaQuery ? "row" : "column"}
      id="sobre">
      <SG.Content padding={mediaQuery ? "24px " : "24px"} >
        <Texto
          fontSize={mediaQuery ? "48px" : "24px"}
          color="#fff">
          Sobre
        </Texto>
        <Texto
          fontSize={mediaQuery ? "24px" : "15px"}
          margin="20px" textalign='left'
          fontWeight="400">
          Olá, sou Wemerson Lucas, um desenvolvedor frontend com experiência em React JS.
          Sou formado em Análise e Desenvolvimento de Sistemas pela Uniararas e estou constantemente aprimorando minhas habilidades em fullstack com Node.js e React JS.
          Apaixonado por desenvolvimento de software, estou sempre em busca de novos desafios e oportunidades para aprender e crescer.
          Ao longo da minha jornada, tive a oportunidade de trabalhar em diversos projetos interessantes, nos quais pude aplicar e aprimorar minhas habilidades técnicas.
        </Texto>
        <Texto
          fontSize={mediaQuery ? "24px" : "15px"}
          margin="20px" textalign='left'
          fontWeight="400">
          Estou comprometido em desenvolver soluções de software eficientes e elegantes, sempre focado na qualidade, usabilidade e
          experiência do usuário. Abaixo, você pode conhecer um pouco dos meus projetos e ver como venho aplicando meus conhecimentos para criar produtos digitais.
          Se você está em busca de um desenvolvedor dedicado, apaixonado por tecnologia e pronto para enfrentar desafios, ficarei feliz em colaborar em seu próximo projeto.
        </Texto>
      </SG.Content>
      {mediaQuery ?
        <SG.Content padding='0 24px 0 0'>
          <Foto
            src="assets/programing.svg"
            alt="programing"
            width="550px"
            height="550px" />
        </SG.Content>
        : null}
    </SG.Content>
  )
}
