import React from 'react'
import * as S from './styles'
import { useHooks } from '../../hooks/useHooks';
import { Nome, Foto } from './styles'
import { HeaderMenu } from '../HeaderMenu';


export function Header() {

  const { mediaQuery } = useHooks()

  const dataButtons = [
    { name: "Inicio" },
    { name: "Sobre", onClick: () => document.getElementById('sobre')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Habilidades", onClick: () => document.getElementById('habilidades')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Projetos", onClick: () => document.getElementById('projetos')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Contato", onClick: () => document.getElementById('contato')?.scrollIntoView({ behavior: "smooth" }) },
  ]


  return (
    <S.HeaderComponent
      padding={mediaQuery ? "0rem 6.9375rem 0rem 4.25rem" : "1.375rem 1.625rem"}
      height={mediaQuery ? "5.75rem" : "3.5rem"}
      id="inicio"
    >
      {mediaQuery ? null : <HeaderMenu />}
      <Nome fontSize={mediaQuery ? "18px" : "16px"}>
        Wemerson Lucas
      </Nome>
      <S.DivButtons>
        {mediaQuery ? (
          <>
            {dataButtons.map((button, index) => (
              <S.StyledButton
                key={index}
                onClick={button.onClick}>
                {button.name}
              </S.StyledButton>
            ))}
          </>
        ) : null}
      </S.DivButtons>
      <Foto src="assets/perfil.png"
        height={mediaQuery ? "74px" : "52px"}
        width={mediaQuery ? "74px" : "52px"} />
    </S.HeaderComponent>
  )
}
