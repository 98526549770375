import React, { useRef, useState } from 'react'
import * as  SG from '../../components/globalStyledCompoent';
import { useHooks } from '../../hooks/useHooks';
import { Texto, Foto, FormStyled, Input, TextArea } from './styles';
import emailjs from '@emailjs/browser';
import { validaEmail } from '../../helpers/helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Contato() {
  const { mediaQuery } = useHooks()
  const form = useRef<HTMLFormElement | null>(null)

  const [email, setEmail] = useState<string>('')
  const [nome, setNome] = useState<string>('')
  const [texto, setTexto] = useState<string>('')


  function sendEmail(e: any) {
    e.preventDefault();

    const formParans = {
      from_name: nome,
      message: texto,
      email: email,
    }

    emailjs.send("service_6sc60fj", "template_0qws4wj", formParans, "Ei8TNHOqSMNOMY8W6")
      .then((result) => {
        setNome('')
        setEmail('')
        setTexto('')
        toast.success("Email enviado com sucesso!", { position: "bottom-right", theme: 'dark' })
      }, (error) => {
        toast.error("Falha ao enviar o Email!", error)
      });
  }

  return (
    <SG.Content
      id="contato"
      padding="24px">
      <Texto
        fontSize={mediaQuery ? "48px" : "24px 24px 0 24px"} >
        Contato
      </Texto>
      <SG.Content flexDirection={mediaQuery ? "row" : "column"} >
        {mediaQuery ?
          <SG.Content
            padding="0 24px">
            <Foto
              src="/assets/contato.svg"
              alt="contato"
              width="500px"
              height="500px" />
          </SG.Content> : null}
        <SG.Content>
          <FormStyled
            ref={form}
            onSubmit={sendEmail}
            padding={mediaQuery ? "100px" : "24px"}>
            <Input
              label="Nome"
              placeholder='Nome'
              variant='outlined'
              size="small"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required />
            <Input
              label="Email"
              placeholder='Email'
              variant='outlined'
              size="small"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required />
            <TextArea
              variant='outlined'
              label="Mensagem"
              placeholder='Mensagem'
              multiline
              value={texto}
              onChange={(e) => setTexto(e.target.value)}
              required
            />
            <SG.ButtonPurple
              margin={mediaQuery ? "30px" : "10px"}
              width="100%"
              type='submit'
              value="enviar"
              disabled={nome === "" || email === "" || texto === "" || !validaEmail.test(email)}
            >
              {"Enviar"}
            </SG.ButtonPurple>
          </FormStyled>
        </SG.Content>
      </SG.Content>
    </SG.Content >
  )
}
