import React from 'react'
import * as  SG from '../../components/globalStyledCompoent';
import { useHooks } from '../../hooks/useHooks';
import { ListaProejtos } from './ListaProjetos';
import { Texto } from './styles';


export function Projetos() {
  const { mediaQuery } = useHooks()

  return (
    <SG.Content
      id='projetos'
      padding='24px'>
      <Texto fontSize={mediaQuery ? "48px" : "24px"} >Projetos</Texto>
      <ListaProejtos />
    </SG.Content>
  )
}
