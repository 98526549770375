import React from 'react'
import * as S from './styles'
import * as  SG from '../../../components/globalStyledCompoent';
import { useHooks } from '../../../hooks/useHooks';
import { CardContainer, Card } from './styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import { retornaUrlFacebook, retornaUrlWhatsApp } from '../../../helpers/helpers';


export function ListaProejtos() {
  const { mediaQuery } = useHooks()

  const projetos = [
    {
      capa: <img src="assets/card_portfolio.png" alt="Imagem ilustrativa" width="100%" height="100%" className='capa' />,
      href: "https://wemersonlucas.com/"
    }
  ]

  const compartilharWhatsApp = (url: string) => {
    window.open(retornaUrlWhatsApp(url), "_blank");
  };

  const compartilharFacebook = (url: string) => {
    window.open(retornaUrlFacebook(url), "_blank");
  };

  return (
    <SG.Content id='projetos'>
      <CardContainer flexDirection={mediaQuery ? "row" : "column"}>
        {projetos.map((button, index) => (
          <Card key={index} onClick={() => window.open(button.href, "_blank")} margin={mediaQuery ? "0 20px" : "24px"}
            flexDirection="column"
            height={mediaQuery ? "400px" : "250px"}
            width={mediaQuery ? "700px" : "100%"}
          >
            {button.capa}
            <S.Botoes>
              <SG.IconButton onClick={(e) => { e.stopPropagation(); compartilharWhatsApp(button.href) }}>
                <WhatsAppIcon />
              </SG.IconButton>
              <SG.IconButton onClick={(e) => { e.stopPropagation(); compartilharFacebook(button.href) }}>
                <FacebookIcon />
              </SG.IconButton>
            </S.Botoes>
          </Card>
        ))}
      </CardContainer>
    </SG.Content>
  )
}
