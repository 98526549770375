import React, { useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import * as S from './styles'

export function HeaderMenu() {

  const [abrirMenu, setAbrirMenu] = useState(false)

  const handleMenuItemClick = (onClick: { (): void | undefined; (): void; }) => {
    onClick();
    setAbrirMenu(false);
  };

  const dataButtons = [
    { name: "Inicio", onClick: () => document.getElementById('inicio')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Sobre", onClick: () => document.getElementById('sobre')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Habilidades", onClick: () => document.getElementById('habilidades')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Projetos", onClick: () => document.getElementById('projetos')?.scrollIntoView({ behavior: "smooth" }) },
    { name: "Contato", onClick: () => document.getElementById('contato')?.scrollIntoView({ behavior: "smooth" }) },
  ]

  return (
    <>
      <S.StyledButton
        width='1.875rem'
        onClick={() => setAbrirMenu(true)}>
        <MenuIcon />
      </S.StyledButton>
      <S.DrawerStyled
        open={abrirMenu}
        onClose={() => setAbrirMenu(false)}
      >
        <S.BotaoFechar onClick={() => setAbrirMenu(false)}>
          <CloseIcon />
        </S.BotaoFechar>
        {dataButtons.map((item, index) => (
          <S.Botao key={index} onClick={() => handleMenuItemClick(item.onClick)}>
            {item.name}
          </S.Botao>
        ))}
      </S.DrawerStyled>
    </>
  )
}
