import React from 'react'
import * as  SG from '../../components/globalStyledCompoent';
import { useHooks } from '../../hooks/useHooks';
import { Texto } from './styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { motion, useScroll } from "framer-motion";

export function Inicio() {
  const { mediaQuery } = useHooks()
  const { scrollYProgress } = useScroll();

  const BaixarPdf = () => {
    window.open('./assets/Curriculo.pdf')
  }

  return (
    <SG.Content padding={mediaQuery ? "24px 0" : "24px"} >
      <motion.div className="progress-bar" style={{ scaleX: scrollYProgress }} />
      <motion.div className="home"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 0.80 }}
        transition={{
          duration: 2.0,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01]
        }}>
        <Texto
          fontSize={mediaQuery ? "32px" : "24px"}
          color="#04d361" flexDirection="row">
          <span className='emotion'>&#128075;</span>Hello world
        </Texto>
        <Texto
          fontSize={mediaQuery ? "56px" : "29px"}
          margin={mediaQuery ? '0 0 16px 0' : " 0px 0 8px 0"}>
          Seja bem vindo(a) ao meu portfólio, aqui você vai encontrar alguns projetos que eu desenvolvi.
        </Texto>
        <Texto
          fontSize={mediaQuery ? "16px" : "15px"}
          color="#E1E1E6" margin={mediaQuery ? '0 0 40px 0' : " 0px 0 10px 0"}
          fontWeight="400">
          Todos os nossos sonhos podem torna-se  realidade se tivermos a coragem de persegui-los. (Walt Disney)
        </Texto>
        <SG.ButtonPurple
          margin='45px'
          onClick={BaixarPdf}
        >
          <span>Curriculum</span> <GetAppIcon />
        </SG.ButtonPurple>
      </motion.div>
    </SG.Content >
  )
}
