import React from 'react';
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import pt from 'date-fns/locale/pt-BR'
import es from 'date-fns/locale/es'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Tema from './Tema';
import { Header } from '../components/Header';
import { Inicio } from '../components/Inicio';
import { Sobre } from '../components/Sobre';
import { Footer } from '../components/Footer';
import { Habilidades } from '../components/Habilidades';
import { Projetos } from '../components/Projetos';
import { Contato } from '../components/Contato';

const App = () => {
  return (
    <Tema >
      <ToastContainer />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={navigator.language === "pt-BR" ? pt : es}>
        <Header />
        <Inicio />
        <Sobre />
        <Habilidades />
        <Projetos />
        <Contato />
        <Footer />
      </MuiPickersUtilsProvider>
    </Tema>
  );
};

export default App;
