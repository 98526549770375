import styled from 'styled-components'
import { StyledProps } from '../../bootstrap/Tema/styledProps'
import TextField from '@material-ui/core/TextField';


export const Texto = styled.h1 <StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: ${props => props.justifycontent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
  text-align: ${props => props.textalign || 'center'};
  color: ${props => props.color || "#fff"};
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight || 700};
  span{
    margin: 5px
  }
`
export const Foto = styled.img <StyledProps>`
  width: ${props => props.width};
  height: ${props => props.height};
`

export const FormStyled = styled.form <StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: ${props => props.padding};
`
export const Input = styled(TextField) <StyledProps>`
  width: 100%;
  .MuiOutlinedInput-root  {
  margin: 12px 0;
  background: #FFFFFF;
  }

  .MuiOutlinedInput-root  fieldset,
  .MuiOutlinedInput-root:hover  fieldset,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #835AFD;
  }

  label {
    color: #202024;
    margin: 6px 0 0 0;
  }
  label.Mui-focused {
    color: #835AFD;
    }

  .MuiInputBase-input {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 136%;
    color:#000;
  }
`
export const TextArea = styled(TextField)`
  &.MuiFormControl-root  {
    width: 100%;

    label{
        color: #202024;
        margin: 0;
      }

    label.Mui-focused {
      color: #835AFD;
    }
  }
  .MuiInputBase-root {
    display: flex;
    background: #FFFFFF;
    color: #8794A5;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 136%;
    border-radius: 8px;
    min-height: 98px;
    resize: none;
    margin: 10px 0 0 0;

  }
  textarea {
    min-height: 98px;
    resize: none;

    :hover{
      color: #000;
    }
  }
`
