import styled from 'styled-components'
import { StyledProps } from '../../../bootstrap/Tema/styledProps'

export const Card = styled.div <StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: center;
  align-items: center;

  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  border: 1px solid #8257E5;
  border-radius: 4px;
  background:rgb(21, 22, 27);
  padding: 24px 8px 8px;
  margin: ${props => props.margin || "0 0 24px 0"};
  cursor: pointer;
`
export const CardContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "row"};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: ${props => props.padding};
`
export const Botoes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 0 10px 0;
`