export const validaEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const retornaUrlWhatsApp = (link: string) => {
  const href = `https://web.whatsapp.com/send?&text=${link}`

  return href
}

export const retornaUrlFacebook = (link: string) => {
  const href = `https://www.facebook.com/sharer/sharer.php?u=${link}`
  return href
}
