import { Button } from '@material-ui/core'
import styled from 'styled-components'
import { StyledProps } from '../../bootstrap/Tema/styledProps'

export const HeaderComponent = styled.header<StyledProps>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${props => props.height};
  justify-content: space-between;
  margin: 0rem 0rem auto 0rem;
  padding: ${props => props.padding};
  background: #29292E;

  svg {
    color: #8257e5;
  }
`
export const StyledButton = styled(Button) <StyledProps>`
  &.MuiButton-root {
    min-width: ${props => props.width};
    background: transparent;
    color: #737380;
    padding: 37px 24px;
    :hover{
      color: #fff;
      border-bottom: solid 3px #8257e5;
    }
  }
`
export const Foto = styled.img`
   display: flex;
   justify-content: center;
   align-items: center;
   width: ${props => props.width};
   height: ${props => props.height};
`
export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4.375rem;
`
export const Nome = styled.h1 <StyledProps>`
  color: #fff;
  font-size: ${props => props.fontSize};
`
