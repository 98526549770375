import styled from "styled-components";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { StyledProps } from "../../bootstrap/Tema/styledProps";
import tokens from '../../bootstrap/Tema/tokens.json'

export const StyledButton = styled(Button) <StyledProps>`
  &.MuiButton-root {
    min-width: ${props => props.width};
    background: transparent;
    padding: 0;
  }
`
export const DrawerStyled = styled(Drawer)`
  .MuiPaper-root{
    background-color: #29292E;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding: 1rem 0rem;
  }
`
export const BotaoFechar = styled(IconButton)`
  &.MuiIconButton-root{
    height: 1.5625rem;
    width: 1.5625rem;
    padding: 1.25rem;
    margin: 0rem 1rem 3.5rem auto;
    color: #fff;
    font-family: ${tokens["token-font-family-base"]};
    font-style: normal;
    font-weight: ${tokens["token-font-weight-bold"]};
    font-size: ${tokens["token-font-size-sm"]};
    line-height: 125%;
  }
`
export const Botao = styled(Button)`
  &.MuiButton-root{
    border-bottom: 0.0625rem solid ${tokens["token-neutralcolor-dark-300"]};
    border-radius: 0rem;
    height: 3rem;
    width: 100%;
    justify-content: flex-start;
    text-transform: none;
    padding: 0rem 0rem 0rem 0.9375rem;
    color: #fff;
    font-weight: ${tokens["token-font-weight-regular"]};
    font-size: ${tokens["token-font-size-xs"]};
    line-height: 150%;

    svg{
      margin: 0rem 1.375rem 0rem 0rem;
    }

    :hover{
      color: #8257e5;
      svg path{
        stroke: #8257e5;
      }
    }
  }
`
