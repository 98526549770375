import styled from 'styled-components'
import { StyledProps } from '../../bootstrap/Tema/styledProps'


export const Texto = styled.h1 <StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.color || "#fff"};
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight || 700};
  span{
    margin: 5px
  }
`
