import React from 'react'
import { useHooks } from '../../hooks/useHooks'
import * as S from './styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import * as  SG from '../globalStyledCompoent';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

export function Footer() {

  const { mediaQuery } = useHooks()

  const listaIcons = [
    { name: "GitHub", icon: <GitHubIcon />, link: "https://github.com/wemersonlucas/wemersonlucas" },
    { name: "LinkedIn", icon: <LinkedInIcon />, link: "https://www.linkedin.com/in/wemerson-lucas-b13aa377/" },
    { name: "Instagram", icon: <InstagramIcon />, link: "https://www.instagram.com/frontendwemerson/" },
  ]
  return (
    <S.Footer
      minHeight={mediaQuery ? "5.75rem" : "3.375rem"}
      fontSize={mediaQuery ? "1rem" : "0.75rem"}
      padding={mediaQuery ? "0 50px" : "0 24px"}
    >
      <S.IconContainer>
        {listaIcons.map((icon, button) => (
          <SG.ButtonPurple key={button} onClick={() => window.open(icon.link, "_blank")}
            backgroundcolor="#505059"
            bordercolor="transparent"
            width={mediaQuery ? "42px" : "10px"}
            height={mediaQuery ? "42px" : "10px"}
            margin={mediaQuery ? "12px" : "6px"}
            padding="0">
            {icon.icon}
          </SG.ButtonPurple>
        ))}
      </S.IconContainer>
      <S.Texto
        fontSize={mediaQuery ? "24px" : "8px"}>
        Copyright © 2024 | Wemerson Lucas
      </S.Texto>
      <SG.ButtonPurple
        onClick={() => document.getElementById('inicio')?.scrollIntoView({ behavior: "smooth" })}
        backgroundcolor="#505059"
        bordercolor="#fff"
        width={mediaQuery ? "42px" : "24px"}
        height={mediaQuery ? "42px" : "24px"}>
        <ExpandLessIcon />
      </SG.ButtonPurple>
    </S.Footer >
  )
}
