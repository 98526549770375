import styled from 'styled-components'
import { Button, CircularProgress } from '@material-ui/core'
import { StyledProps } from '../bootstrap/Tema/styledProps'


export const Container = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  overflow: hidden;
`
export const Content = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  align-items: ${props => props.alignItems || "center"};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  padding: ${props => props.padding};
  overflow: ${props => props.overflow || "hidden"};
  margin: ${props => props.margin};
  border: ${props => props.border};
  border-radius: ${props => props.radius};
  justify-content: ${props => props.justifycontent || "center"};
  background: ${props => props.background || 'rgb(18, 18, 20);'};
`
export const ButtonPurple = styled(Button) <StyledProps>`
  &.MuiButtonBase-root {
    color: ${props => props.textcolor || '#fff'};
    background-color: ${props => props.backgroundcolor || "#8257E5"};
    border: 0.0625rem solid ${props => props.bordercolor || "#8257E5"};
    text-transform: none;
    padding: ${props => props.padding || "1rem 1.5rem"};
    height: ${props => props.height || "3.125rem"};
    width: ${props => props.width || "19.5rem"};
    margin: ${props => props.margin};
    border-radius: 0.5rem;
    min-height: 12px;
    min-width: 12px;

    span{
      margin: 50px;
    }
  }
  &.Mui-disabled {
    background-color: ${props => props.backgroundcolor || "#8257E5"};
    border: none;
  }
`
export const Loader = styled(CircularProgress) <StyledProps>`
  &.MuiCircularProgress-root {
    margin: ${props => props.margin};
    color: #8257E5;
  }
`
export const IconButton = styled(Button)`
  &.MuiButtonBase-root {
    color: #fff;
    text-transform: none;
    height: 24px;
    width: 24px;
    margin: 0;
    min-height: 24px;
    min-width: 24px;
  }

  &.MuiButtonBase-root:hover {
    color: #8257E5;
  }
`